import type { RouteParamsGeneric } from 'vue-router';

interface PathReplaceParams {
    market?: string;
    language?: string;
}

export function replacePathParams(path: string, params: PathReplaceParams): string {
    const parts = path.split('/');

    return [
        parts[0],
        params.market?.toLowerCase() ?? parts[1],
        params.language?.toLowerCase() ?? parts[2],
        // eslint-disable-next-line no-magic-numbers
        ...parts.slice(3),
    ].join('/');
}

export function stringValueOfParam(raw: RouteParamsGeneric[keyof RouteParamsGeneric] | undefined): string {
    const value = Array.isArray(raw) ? raw[0] : raw;

    return value ?? '';
}
