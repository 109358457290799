// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { type RouteRecordRaw } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { initNavAreaMyArea } from '@/router/guards/init-nav-area';

export const changelogRoutes: RouteRecordRaw = {
    path: 'changelog',
    name: Navigation.Changelog,
    component: async () => import('./views/ChangelogView.vue'),
    beforeEnter: [initNavAreaMyArea],
};
