<script setup lang="ts">
    import FloatLabel from 'primevue/floatlabel';
    import { ref, type Ref } from 'vue';

    defineProps<{
        label: string;
        errorMessage?: string;
    }>();

    const inputRef = ref();

    function setInputRef(newRef: Ref<unknown>): void {
        inputRef.value = newRef;
    }
</script>

<template>
    <div class="form-field-container">
        <FloatLabel>
            <slot :ref="setInputRef" />
            <label :for="inputRef">{{ label }}</label>
        </FloatLabel>
        <small class="p-error">{{ errorMessage }}</small>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .form-field-container {
        padding-top: main.$spacing-7;
    }
</style>
