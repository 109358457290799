import { type NavigationGuard } from 'vue-router';
import { useMarketAction } from '@/composables/market';
import { useLanguage } from '@/composables/useLanguage';

// eslint-disable-next-line func-style
export const inferParams: NavigationGuard = async (to) => {
    const marketAction = useMarketAction();
    const language = useLanguage();

    const code = (await marketAction.initMarketData()).toLowerCase();
    const locale = language.getInitialLanguageLocale().toLowerCase();

    if (code === '' || locale === '') {
        return false;
    }

    const cleanedPath = to.path.startsWith('/') ? to.path.slice(1) : to.path;

    return {
        path: `/${code}/${locale}/${cleanedPath}`,
    };
};
