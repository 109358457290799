import { defineStore } from 'pinia';

interface CartSidebarState {
    isVisible: boolean;
    lastLineItemName: string;
}

export const useCartSidebarStore = defineStore('cartSidebar', {
    state: (): CartSidebarState => ({
        isVisible: false,
        lastLineItemName: '',
    }),
    actions: {
        setIsVisible(newValue: boolean): void {
            this.isVisible = newValue;
        },
        setLastLineItemName(newValue: string): void {
            this.lastLineItemName = newValue;
        },
    },
});
