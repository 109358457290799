import { defineStore } from 'pinia';
import { type MarketDto } from '@containex/portal-backend-dto';
import { computed, ref } from 'vue';

export const useMarketStore = defineStore('market', () => {
    const market = ref<MarketDto | null>(null);
    const availableMarkets = ref<MarketDto[]>([]);
    const identifiedZipCode = ref<string | undefined>(undefined);

    return {
        market: computed(() => market.value),
        availableMarkets,
        identifiedZipCode: computed(() => identifiedZipCode.value),
        setMarket(code: string): void {
            const marketToSet = availableMarkets.value.find((m) => m.code.toLowerCase() === code.toLowerCase());

            if (marketToSet != null) {
                market.value = marketToSet;
            }
        },
        setIdentifiedZipCode(zipCode: string | undefined): void {
            identifiedZipCode.value = zipCode;
        },
    };
});
