import { type MedusaError, MedusaErrorCodes } from '@medusajs/utils/dist/common/errors';
import useGlobalToast from '@/composables/useGlobalToast';
import { instanceOfMedusaCart, instanceOfMedusaError } from '@/util/instanceOfHelpers';
import { type Cart, type LineItem } from '@containex/portal-backend-api-client';

interface ToastHelpers {
    showToastForAddItemResponse(result: Cart | MedusaError, productTitle: string): void;
    showToastForRemoveItemResponse(result: Cart | MedusaError, productTitle: string): void;
    showErrorToastForChangeQuantityResponse(result: MedusaError, lineItem: LineItem, newQuantity: number): void;
    showToastForCartShippingAddressUpdate(result: Cart | MedusaError): void;
    showToastForCartCompleteError(): void;
    showToastForCartCreationError(): void;
}

export function useToastHelpers(): ToastHelpers {
    const { defaultToastOptions, errorToastOptions, addToast } = useGlobalToast();

    return {
        showToastForAddItemResponse(result: Cart | MedusaError, productTitle: string): void {
            if (instanceOfMedusaError(result)) {
                if (result.code === MedusaErrorCodes.INSUFFICIENT_INVENTORY) {
                    addToast({
                        ...errorToastOptions,
                        detail: 'ERROR.INSUFFICIENT_INVENTORY_DETAIL',
                    });
                } else {
                    addToast(errorToastOptions);
                }
            }
        },
        showToastForRemoveItemResponse(result: Cart | MedusaError, productTitle: string): void {
            if (instanceOfMedusaCart(result)) {
                addToast({
                    ...defaultToastOptions,
                    severity: 'success',
                    summary: 'CART.TOAST.REMOVE_ITEM_FROM_CART_SUCCESS.SUMMARY',
                    detail: 'CART.TOAST.REMOVE_ITEM_FROM_CART_SUCCESS.DETAIL',
                    detailValues: { productTitle },
                });
            } else {
                addToast({
                    ...errorToastOptions,
                });
            }
        },
        showErrorToastForChangeQuantityResponse(result: MedusaError, lineItem: LineItem, newQuantity: number): void {
            if (result.code === MedusaErrorCodes.INSUFFICIENT_INVENTORY) {
                addToast({
                    ...errorToastOptions,
                    summary: 'ERROR.INSUFFICIENT_QUANTITY_SUMMARY',
                    detail: 'ERROR.INSUFFICIENT_QUANTITY_DETAIL',
                });
            } else {
                addToast(errorToastOptions);
            }
        },
        showToastForCartShippingAddressUpdate(result: Cart | MedusaError): void {
            if (instanceOfMedusaError(result)) {
                addToast(errorToastOptions);
            } else {
                addToast({
                    ...defaultToastOptions,
                    severity: 'success',
                    summary: 'CART.TOAST.UPDATE_SHIPPING_ADDRESS.SUMMARY',
                    detail: 'CART.TOAST.UPDATE_SHIPPING_ADDRESS.DETAIL',
                });
            }
        },
        showToastForCartCompleteError(): void {
            addToast({
                ...errorToastOptions,
                summary: 'CART.TOAST.COMPLETE_CART_ERROR.SUMMARY',
                detail: 'CART.TOAST.COMPLETE_CART_ERROR.DETAIL',
            });
        },
        showToastForCartCreationError(): void {
            addToast({
                ...errorToastOptions,
                summary: 'CART.TOAST.CREATE_CART_ERROR.SUMMARY',
                detail: 'CART.TOAST.CREATE_CART_ERROR.DETAIL',
            });
        },
    };
}
