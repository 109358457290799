import { defineStore } from 'pinia';
import { NavArea } from '@/types/NavArea';

interface NavigationItemsState {
    activeArea: NavArea;
}

export const useNavigationItemsStore = defineStore('navigation-items', {
    state: (): NavigationItemsState => ({
        activeArea: NavArea.BUY,
    }),
    actions: {
        setActiveArea(newValue: NavArea): void {
            this.activeArea = newValue;
        },
    },
});
