import { setI18nLanguage } from '@/i18n/i18n';
import * as dateFnsLocales from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import { isDefined } from '@containex/common-utils';
import { usePrimeVue } from 'primevue/config';

interface UseLanguage {
    availableLanguages: string[];
    fallbackLocale: string;
    setLanguage(this: void, locale: string): void;
    getInitialLanguageLocale(this: void): string;
    countryCodeToLocalName(this: void, code: string): string;
}

const availableLanguages = ['de', 'en'];
const fallbackLocale = 'en';

const localeMapping: Record<string, string> = {
    en: 'enGB',
    fa: 'faIR',
    zh: 'zhCN',
};

// we need to map specific locals that don't work with our 2-digit codes
function mapLocale(locale: string): string {
    const mappedLocale = localeMapping[locale];
    if (mappedLocale != null) {
        return mappedLocale;
    }
    return locale;
}

export function useLanguage(): UseLanguage {
    const primevue = usePrimeVue();

    return {
        availableLanguages,
        fallbackLocale,
        setLanguage(locale: string): void {
            // wrapper for setI18nLanguage with validation
            const validatedLocale = getAllowedLanguageLocaleOrFallback(locale);

            setI18nLanguage(validatedLocale);

            void setPrimeVueLocale(primevue, validatedLocale);

            const entry =
                Object.entries(dateFnsLocales).find(([key]) => key === mapLocale(getLanguageBaseLocale(locale))) ?? [];

            setDefaultOptions({
                locale: entry[1],
            });
        },
        getInitialLanguageLocale(): string {
            return getInitialLanguageLocale();
        },
        countryCodeToLocalName(code: string): string {
            const lang = getInitialLanguageLocale();
            const countryName = new Intl.DisplayNames([lang], { type: 'region' });
            return countryName.of(code.toUpperCase()) ?? code;
        },
    };
}

function isLanguageVariantLocale(locale: string): boolean {
    // Use a regular expression to check if the locale has a country code or is a Chinese variant
    const chinesePattern = /^zh(?:-[A-Za-z]+)?$/;
    const otherLanguagePattern = /^[a-z]{2}(?:-[A-Z]{2})?$/;

    return chinesePattern.test(locale) || otherLanguagePattern.test(locale);
}

function getLanguageBaseLocale(locale: string): string {
    return locale.substring(0, 2);
}

function isAllowedLanguageLocale(locale: string): boolean {
    return availableLanguages.includes(locale);
}

function isAllowedLanguageVariantLocale(locale: string): boolean {
    return availableLanguages.includes(getLanguageBaseLocale(locale));
}

function getAllowedLanguageLocaleOrFallback(locale: string): string {
    if (isAllowedLanguageLocale(locale)) {
        // it's either a base language or a variant. The language is allowed
        return locale;
    } else if (isLanguageVariantLocale(locale) && isAllowedLanguageLocale(getLanguageBaseLocale(locale))) {
        // it's a language variant which is not allowed, but the base language is allowed
        return getLanguageBaseLocale(locale);
    }

    return fallbackLocale;
}

function getInitialLanguageLocale(): string {
    const relevantLocales = [navigator.language, ...navigator.languages];
    const preferredLocale =
        relevantLocales
            .filter(isDefined)
            .find((lang) => isAllowedLanguageLocale(lang) || isAllowedLanguageVariantLocale(lang)) ?? fallbackLocale;

    return getAllowedLanguageLocaleOrFallback(preferredLocale);
}

async function setPrimeVueLocale(primevue: ReturnType<typeof usePrimeVue>, locale: string): Promise<void> {
    switch (locale) {
        case 'de': {
            primevue.config.locale = (await import('@/prime-locale/de')).deTranslations;
            break;
        }
        case 'en':
        default: {
            primevue.config.locale = (await import('@/prime-locale/en')).enTranslations;
            break;
        }
    }
}
