import { type NavigationGuard } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { useCartQuery } from '@/composables/cart';
import { stringValueOfParam } from '@/util/param';
import { getComputedCartUrlByProvisionType } from '@/util/router';

// eslint-disable-next-line func-style
export const requireCartHasItems: NavigationGuard = (to) => {
    const { lineItemsQuantityCount } = useCartQuery();
    const cartUrl = getComputedCartUrlByProvisionType();

    return lineItemsQuantityCount.value > 0
        ? true
        : {
              name: cartUrl.value ?? Navigation.SalesCart,
              params: {
                  market: stringValueOfParam(to.params.market).toLowerCase(),
                  language: stringValueOfParam(to.params.language).toLowerCase(),
              },
          };
};
