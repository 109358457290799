const modules = import.meta.glob('../../../../node_modules/zod-i18n-map/locales/*/zod.json', {
    query: '?json',
});

export async function zodTranslationsFor(locale: string): Promise<unknown> {
    return await modules[translationPathFor(locale)]?.();
}

function translationPathFor(locale: string): string {
    return `../../../../node_modules/zod-i18n-map/locales/${locale}/zod.json`;
}
