import { type CtxProduct } from '@containex/portal-backend-api-client';
import { defineStore } from 'pinia';

interface OrderInfoSidebarState {
    isVisible: boolean;
    productToAdd: CtxProduct | null;
}

export const useOrderInfoSidebarStore = defineStore('orderInfoSidebar', {
    state: (): OrderInfoSidebarState => ({
        isVisible: false,
        productToAdd: null,
    }),
    actions: {
        setIsVisible(newValue: boolean): void {
            this.isVisible = newValue;
        },
        setProductToAdd(product: CtxProduct | null) {
            this.productToAdd = product;
        },
    },
});
