import { getLogger, initRootLogger } from '@containex/logger';
import { setupGlobalErrorHandler } from '@/logger/global-error-handler';
import { addGlobalVueErrorHandler } from '@/logger/global-error-handler-vue';

export { getLogger } from '@containex/logger';

initRootLogger({
    level: import.meta.env.PROD ? 'warn' : 'info',
});

const logger = getLogger('global');

if (import.meta.env.PROD) {
    setupGlobalErrorHandler();
    addGlobalVueErrorHandler((error) => {
        logger.error(error, 'Error caught globally within Vue.js context');
    });
}
