import { defineStore } from 'pinia';
import type { CompanyDto } from '@containex/portal-backend-dto';

interface CompanyState {
    company: CompanyDto | null;
}

export const useCompanyStore = defineStore('company', {
    state: (): CompanyState => ({
        company: null,
    }),
});
