import { type NavigationGuard } from 'vue-router';
import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication';
import { Navigation } from '@/router/navigation';
import { stringValueOfParam } from '@/util/param';

// eslint-disable-next-line func-style
export const requireLoggedIn: NavigationGuard = async (to) => {
    const authenticationAction = useAuthenticationAction();
    const authenticationQuery = useAuthenticationQuery();

    await authenticationAction.fetchCurrentCustomerIfMissing.perform();

    return authenticationQuery.isUserLoggedIn.value
        ? true
        : {
              name: Navigation.Login,
              params: {
                  market: stringValueOfParam(to.params.market).toLowerCase(),
                  language: stringValueOfParam(to.params.language).toLowerCase(),
              },
          };
};
