import { errorContainsStatusCode } from '@/util/error-contains-status-code';
import { HttpStatusCode } from 'axios';
import useGlobalToast from '@/composables/useGlobalToast';
import { getLogger } from '@/logger/logger';

interface UseErrorHandler {
    errorHandlerFrom(option: Partial<ErrorHandlerOptions>): ErrorHandler;
}

interface ErrorHandler {
    handle(fn: () => Promise<void>): Promise<void>;
}

interface ErrorHandlerOptions {
    name: string;
    statusCodeHandlers: Record<number, (code: HttpStatusCode, error: unknown) => void>;
    defaultHandler: (error: unknown) => void;
}

export function useErrorHandler(): UseErrorHandler {
    return {
        errorHandlerFrom,
    };
}

function errorHandlerFrom(option: Partial<ErrorHandlerOptions>): ErrorHandler {
    const { errorToastOptions, addToast } = useGlobalToast();
    const logger = getLogger(option.name);

    return {
        async handle(fn: () => Promise<void>): Promise<void> {
            try {
                await fn();
            } catch (error: unknown) {
                if (option.statusCodeHandlers != null && errorContainsStatusCode(error)) {
                    const handler = option.statusCodeHandlers[error.request.status];

                    if (handler != null) {
                        logger.warn(error);
                        handler(error.request.status, error);
                        return;
                    }
                }

                if (option.defaultHandler != null) {
                    logger.warn(error);
                    option.defaultHandler(error);
                } else {
                    logger.error(error);
                    addToast(errorToastOptions);
                }
            }
        },
    };
}
