// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { type RouteRecordRaw } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { initNavAreaByProvisionType } from '@/router/guards/init-nav-area';
import { initCart } from '@/router/guards/init-cart';
import { ensureProvisionType } from '@/router/guards/ensure-provision-type';

export const cartRoutes: RouteRecordRaw = {
    path: '',
    children: [
        {
            path: 'sales/cart',
            name: Navigation.SalesCart,
            component: async () => import('./views/SalesCartView.vue'),
            beforeEnter: [ensureProvisionType, initNavAreaByProvisionType, initCart],
        },
        {
            path: 'rental/cart',
            name: Navigation.RentalCart,
            component: async () => import('./views/RentalCartView.vue'),
            beforeEnter: [ensureProvisionType, initNavAreaByProvisionType, initCart],
        },
    ],
};
