import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { computed, ref } from 'vue';
import { type Cart } from '@containex/portal-backend-api-client';
import { DepotMinimalDto, TransportCostDto } from '@containex/portal-backend-dto';
import type { CartStoreState } from '@/model/cart-store';

const RENTAL_CART_ID = 'RENTAL_CART_ID';

export const useRentalCartStore = defineStore<'rentalCartStore', CartStoreState>('rentalCartStore', () => {
    const cartId = useStorage<string | null>(RENTAL_CART_ID, null);
    const cart = ref<Cart | null>(null);
    const orderNote = ref<string | undefined>();
    const transportCosts = ref<TransportCostDto[]>([]);
    const rentalDepots = ref<DepotMinimalDto[]>([]);

    return {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        cart: computed(() => cart.value as Cart | null),
        cartId: computed(() => cartId.value),
        transportCosts: computed(() => transportCosts.value),
        rentalDepots: computed(() => rentalDepots.value),
        setCart(newCart: Cart): void {
            cart.value = newCart;
            cartId.value = newCart.id;
        },
        setTransportCosts(newTransportCosts: TransportCostDto[]): void {
            transportCosts.value = newTransportCosts;
        },
        resetCartStore(): void {
            cart.value = null;
            cartId.value = null;
            orderNote.value = undefined;
            transportCosts.value = [];
            rentalDepots.value = [];
        },
        setRentalDepots(newRentalDepots: DepotMinimalDto[]): void {
            rentalDepots.value = newRentalDepots;
        },
    };
});
