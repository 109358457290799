// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { type RouteRecordRaw } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { initNavAreaByProvisionType } from '@/router/guards/init-nav-area';
import { initCart } from '@/router/guards/init-cart';
import { ensureProvisionType } from '@/router/guards/ensure-provision-type';

export const productRoutes: RouteRecordRaw = {
    path: '',
    children: [
        {
            path: 'sales/products',
            beforeEnter: [ensureProvisionType, initNavAreaByProvisionType, initCart],
            children: [
                {
                    path: '',
                    name: Navigation.SalesProductList,
                    component: async () => import('./sales/views/SalesProductListView.vue'),
                },
                {
                    path: ':id/:variantId?',
                    name: Navigation.SalesProductDetail,
                    props: true,
                    component: async () => import('./sales/views/SalesProductDetailView.vue'),
                },
            ],
        },
        {
            path: 'rental/products',
            beforeEnter: [ensureProvisionType, initNavAreaByProvisionType, initCart],
            children: [
                {
                    path: '',
                    name: Navigation.RentalProductList,
                    component: async () => import('./rental/views/RentalProductListView.vue'),
                },
                {
                    path: ':id/:variantId?',
                    name: Navigation.RentalProductDetail,
                    props: true,
                    component: async () => import('./rental/views/RentalProductDetailView.vue'),
                },
            ],
        },
    ],
};
