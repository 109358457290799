import { computed, type ComputedRef, type Ref } from 'vue';
import { useCartSidebarStore } from '@/stores/cart-sidebar';

export interface CartSidebarQuery {
    isVisible: ComputedRef<boolean>;
    lastLineItemName: Ref<string>;
}

export interface CustomerAction {
    setIsVisible(newValue: boolean): void;
    setLastLineItemName(newValue: string): void;
}

export function useCartSidebarQuery(): CartSidebarQuery {
    const store = useCartSidebarStore();

    return {
        isVisible: computed(() => store.isVisible),
        lastLineItemName: computed(() => store.lastLineItemName),
    };
}

export function useCartSidebarAction(): CustomerAction {
    const store = useCartSidebarStore();
    return {
        setIsVisible(newValue: boolean): void {
            store.setIsVisible(newValue);
        },
        setLastLineItemName(newValue: string): void {
            store.setLastLineItemName(newValue);
        },
    };
}
