import { type NavigationGuard } from 'vue-router';
import { ProvisionType } from '@containex/portal-backend-dto';
import { useMarketQuery } from '@/composables/market';
import { Navigation } from '../navigation';
import { stringValueOfParam } from '@/util/param';
import { useProvisionTypeQuery } from '@/composables/provision-type';

// eslint-disable-next-line func-style
export const ensureProvisionType: NavigationGuard = (to) => {
    const { market } = useMarketQuery();
    const { currentProvisionType } = useProvisionTypeQuery();

    if (market.value == null) {
        return true;
    }
    if (currentProvisionType.value == null) {
        return true;
    }

    if (!market.value.provision_types.includes(currentProvisionType.value)) {
        switch (currentProvisionType.value) {
            case ProvisionType.Sales:
                return {
                    name: Navigation.RentalProductList,
                    params: {
                        market: stringValueOfParam(to.params.market).toLowerCase(),
                        language: stringValueOfParam(to.params.language).toLowerCase(),
                    },
                };
            case ProvisionType.Rental:
                return {
                    name: Navigation.SalesProductList,
                    params: {
                        market: stringValueOfParam(to.params.market).toLowerCase(),
                        language: stringValueOfParam(to.params.language).toLowerCase(),
                    },
                };
        }
    }
};
