import { type NavigationGuard } from 'vue-router';
import { useCartAction } from '@/composables/cart';

// eslint-disable-next-line func-style
export const initCart: NavigationGuard = async (to) => {
    const cartAction = useCartAction();

    await cartAction.ensureCartExists();
    return true;
};
