type ErrorHandler = (error: unknown) => void;

const handlers: ErrorHandler[] = [];

export function handleGlobalVueError(error: unknown): void {
    handlers.forEach((handler) => {
        handler(error);
    });
}

export function addGlobalVueErrorHandler(handler: ErrorHandler): void {
    handlers.push(handler);
}
