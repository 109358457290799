// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { useAuthenticationQuery } from '@/composables/authentication';
import { Navigation } from '@/router/navigation';
import { accountRoutes } from '@/account/routes';
import { requireNotLoggedIn } from '@/router/guards/require-not-logged-in';
import { changelogRoutes } from '@/changelog/routes';
import { inferParams } from '@/router/guards/infer-params';
import { inferParamsIfMissing } from '@/router/guards/infer-params-if-missing';
import { initMarket } from '@/router/guards/init-market';
import { initCart } from '@/router/guards/init-cart';
import { requireLoggedIn } from '@/router/guards/require-logged-in';
import { requireCartHasItems } from '@/router/guards/require-cart-has-items';
import { initLanguage } from '@/router/guards/init-language';
import { productRoutes } from '@/product/routes';
import { cartRoutes } from '@/cart/routes';
import { initNavAreaByProvisionType, initNavAreaMyArea } from '@/router/guards/init-nav-area';
import { inferProvisionTypeAndFetchNewLeadsCount } from './guards/infer-provision-type-and-fetch-new-leads-count';
import { ensureProvisionType } from './guards/ensure-provision-type';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: withCodeParams([
        {
            path: '',
            component: async () => import('../views/NavBarView.vue'),
            children: [
                {
                    path: '',
                    name: Navigation.Landing,
                    redirect: {
                        name: Navigation.SalesProductList,
                    },
                    beforeEnter: [initNavAreaByProvisionType],
                },
                {
                    path: 'sales/cart/checkout/success',
                    name: Navigation.SalesCheckoutSuccess,
                    component: async () => import('../checkout/views/CheckoutSuccessView.vue'),
                    beforeEnter: [
                        (to, from, next) => {
                            const { isUserLoggedIn } = useAuthenticationQuery();

                            if (isUserLoggedIn.value) {
                                next();
                            } else {
                                next(Navigation.Login);
                            }
                        },
                        ensureProvisionType,
                        initNavAreaByProvisionType,
                    ],
                },
                {
                    path: 'rental/cart/checkout/success',
                    name: Navigation.RentalCheckoutSuccess,
                    component: async () => import('../checkout/views/CheckoutSuccessView.vue'),
                    beforeEnter: [
                        (to, from, next) => {
                            const { isUserLoggedIn } = useAuthenticationQuery();

                            if (isUserLoggedIn.value) {
                                next();
                            } else {
                                next(Navigation.Login);
                            }
                        },
                        ensureProvisionType,
                        initNavAreaByProvisionType,
                    ],
                },
                {
                    path: 'signup',
                    name: Navigation.Signup,
                    component: async () => import('../views/SignupView.vue'),
                    beforeEnter: [initNavAreaMyArea],
                },
                {
                    path: 'signup/activate',
                    props: (route) => ({
                        token: route.query.token,
                        email: route.query.email,
                    }),
                    beforeEnter: [
                        (to, from, next) => {
                            const token = to.query.token;
                            const email = to.query.email;
                            if (token == null || email == null) {
                                next(Navigation.NotFound);
                            } else {
                                next();
                            }
                        },
                        initNavAreaMyArea,
                    ],
                    name: Navigation.NewSignupActivateCustomer,
                    component: async () => import('../views/NewSignupCustomerActivateView.vue'),
                },
                {
                    path: 'signup/activate/success',
                    name: Navigation.ActivateCustomerSuccess,
                    component: async () => import('../views/ActivateCustomerSuccessView.vue'),
                    beforeEnter: [initNavAreaMyArea],
                },
                {
                    path: 'signup-success',
                    name: Navigation.SignupSuccess,
                    component: async () => import('../views/SignupSuccessView.vue'),
                    beforeEnter: [initNavAreaMyArea],
                },
                {
                    path: 'signup-selection',
                    name: Navigation.SignupSelection,
                    component: async () => import('../views/SignupSelection.vue'),
                    beforeEnter: [initNavAreaMyArea],
                },
                {
                    path: 'fast-track-registration-success',
                    name: Navigation.FastTrackRegistrationSuccess,
                    component: async () => import('../views/FastTrackSignupSuccessView.vue'),
                    beforeEnter: [initNavAreaMyArea],
                },
                {
                    path: 'new-registration-success',
                    name: Navigation.NewRegistrationSuccess,
                    component: async () => import('../views/NewRegistrationViewSuccess.vue'),
                    beforeEnter: [initNavAreaMyArea],
                },
                {
                    path: 'login',
                    name: Navigation.Login,
                    component: async () => import('../views/LoginView.vue'),
                    beforeEnter: [requireNotLoggedIn, initNavAreaMyArea],
                },
                accountRoutes,
                cartRoutes,
                productRoutes,
                {
                    path: 'forgot-password',
                    name: Navigation.ForgotPassword,
                    component: async () => import('../views/ForgotPasswordView.vue'),
                    beforeEnter: [initNavAreaMyArea],
                },
                {
                    path: 'reset-password',
                    props: (route) => ({
                        token: route.query.token,
                        email: route.query.email,
                    }),
                    beforeEnter: [
                        (to, from, next) => {
                            const token = to.query.token;
                            const email = to.query.email;
                            if (token == null || email == null) {
                                next(Navigation.NotFound);
                            } else {
                                next();
                            }
                        },
                        initNavAreaMyArea,
                    ],
                    name: Navigation.ResetPassword,
                    component: async () => import('../views/PasswordResetView.vue'),
                },
                {
                    path: 'activate-customer',
                    props: (route) => ({
                        token: route.query.token,
                        email: route.query.email,
                    }),
                    beforeEnter: [
                        (to, from, next) => {
                            const token = to.query.token;
                            const email = to.query.email;
                            if (token == null || email == null) {
                                next(Navigation.NotFound);
                            } else {
                                next();
                            }
                        },
                        initNavAreaMyArea,
                    ],
                    name: Navigation.ActivateCustomer,
                    component: async () => import('../views/CustomerActivateView.vue'),
                },
                {
                    path: 'reset-password-success',
                    name: Navigation.ResetPasswordSuccess,
                    component: async () => import('../views/PasswordResetSuccessView.vue'),
                    beforeEnter: [initNavAreaMyArea],
                },
                {
                    path: 'reset-password-request-success',
                    name: Navigation.ResetPasswordRequestSuccess,
                    component: async () => import('../views/PasswordResetRequestSuccessView.vue'),
                    beforeEnter: [initNavAreaMyArea],
                },
                changelogRoutes,
            ],
        },
        {
            path: 'sales/cart/checkout',
            name: Navigation.SalesCheckout,
            component: async () => import('../checkout/views/SaleCheckoutView.vue'),
            beforeEnter: [
                ensureProvisionType,
                requireLoggedIn,
                requireCartHasItems,
                initNavAreaByProvisionType,
                initCart,
            ],
        },
        {
            path: 'rental/cart/checkout',
            name: Navigation.RentalCheckout,
            component: async () => import('../checkout/views/RentalCheckoutView.vue'),
            beforeEnter: [
                ensureProvisionType,
                requireLoggedIn,
                requireCartHasItems,
                initNavAreaByProvisionType,
                initCart,
            ],
        },
        {
            path: ':pathMatch(.*)*',
            component: async () => import('../views/NavBarView.vue'),
            children: [
                {
                    name: Navigation.NotFound,
                    path: '',
                    component: async () => import('../views/NotFoundView.vue'),
                    beforeEnter: [initNavAreaMyArea],
                },
            ],
        },
    ]),
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach(inferProvisionTypeAndFetchNewLeadsCount);

export default router;

function withCodeParams(children: RouteRecordRaw[]): RouteRecordRaw[] {
    return [
        {
            path: '/:market/:language',
            beforeEnter: [inferParamsIfMissing, initMarket, initLanguage],
            children,
        },
        {
            path: '/:pathMatch(.*)*',
            component: () => null,
            beforeEnter: [inferParams],
        },
    ];
}
