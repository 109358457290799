import { computed, type ComputedRef } from 'vue';
import { useOrderInfoSidebarStore } from '@/stores/order-info-sidebar';
import { type CtxProduct } from '@containex/portal-backend-api-client';

export interface OrderInfoSidebarQuery {
    isVisible: ComputedRef<boolean>;
    productToAdd: ComputedRef<CtxProduct | null>;
}

export interface OrderInfoSidebarAction {
    setIsVisible(newValue: boolean): void;
    setProductToAdd(product: CtxProduct | null): void;
}

export function useOrderInfoSidebarQuery(): OrderInfoSidebarQuery {
    const store = useOrderInfoSidebarStore();

    return {
        isVisible: computed(() => store.isVisible),
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        productToAdd: computed(() => store.productToAdd as CtxProduct),
    };
}

export function useOrderInfoSidebarAction(): OrderInfoSidebarAction {
    const store = useOrderInfoSidebarStore();

    return {
        setIsVisible(newValue: boolean): void {
            store.setIsVisible(newValue);
        },
        setProductToAdd(product: CtxProduct | null): void {
            store.setProductToAdd(product);
        },
    };
}
