import { type NavigationGuard } from 'vue-router';
import { stringValueOfParam } from '@/util/param';
import { useMarketStore } from '@/stores/market';

// eslint-disable-next-line func-style
export const initMarket: NavigationGuard = (to) => {
    const marketStore = useMarketStore();

    const marketCode = stringValueOfParam(to.params.market);
    marketStore.setMarket(marketCode);

    return true;
};
