<script setup lang="ts">
    import { RouterView, useRoute } from 'vue-router';
    import { useLanguage } from '@/composables/useLanguage';
    import useGlobalToast, { GLOBAL_TOAST_GROUP_NAME } from '@/composables/useGlobalToast';
    import PortalFooter from '@/components/PortalFooter.vue';
    import Toast from 'primevue/toast';
    import ConfirmPopup from 'primevue/confirmpopup';
    import CartSidebar from './components/cart/CartSidebar.vue';
    import { HttpStatusCode, isAxiosError } from 'axios';
    import { addGlobalVueErrorHandler } from '@/logger/global-error-handler-vue';
    import { useAuthenticationAction } from '@/composables/authentication';
    import StageBadge from '@/components/StageBadge.vue';
    import OrderInfoSidebar from '@/components/catalog-settings/OrderInfoSidebar.vue';
    import { watch } from 'vue';
    import { stringValueOfParam } from '@/util/param';
    import MarketSwitchConfirmation from '@/components/navigation/MarketSwitchConfirmation.vue';
    import { useProvisionTypeQuery } from './composables/provision-type';

    const { setLanguage } = useLanguage();
    const { errorToastOptions, addToast } = useGlobalToast();
    const authenticationAction = useAuthenticationAction();
    const route = useRoute();
    const { currentProvisionType } = useProvisionTypeQuery();

    addGlobalVueErrorHandler((error) => {
        if (isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized) {
            addToast({
                ...errorToastOptions,
                summary: 'ERROR.SESSION_EXPIRED.SUMMARY',
                detail: 'ERROR.SESSION_EXPIRED.DETAIL',
            });
            authenticationAction.deleteExpiredSession();
        }
    });

    watch(
        () => route.params.language,
        (newLanguage) => {
            setLanguage(stringValueOfParam(newLanguage));
        }
    );
</script>

<template>
    <RouterView />

    <PortalFooter />
    <Toast position="bottom-center" :group="GLOBAL_TOAST_GROUP_NAME" />
    <MarketSwitchConfirmation />
    <ConfirmPopup />
    <CartSidebar />
    <OrderInfoSidebar v-if="currentProvisionType != null" />
    <StageBadge></StageBadge>
</template>
