import type { CtxProductVariant } from '@containex/portal-backend-api-client';
import { DepotType } from '@containex/portal-backend-dto';

export function filterProductVariantForProvisionType(
    variant: CtxProductVariant,
    countryCode: string | undefined,
    isRental: boolean
): boolean {
    if (isRental) {
        return variant.depotQuantity.depot.type === DepotType.Rental;
    }
    return (
        variant.depotQuantity.depot.type === DepotType.ProductionSite ||
        variant.depotQuantity.depot.address.country_code?.toLowerCase() === countryCode?.toLowerCase()
    );
}
