import { defineStore } from 'pinia';
import type { CheckoutDeliveryOption } from '@/checkout/model/checkout-delivery-option';

interface CheckoutDeliveryOptionState {
    lineItemGroupDeliveryOptionSelection: Record<string, CheckoutDeliveryOption>;
}

export const useCheckoutDeliveryOptionStore = defineStore('checkout-delivery-option', {
    state: (): CheckoutDeliveryOptionState => ({
        lineItemGroupDeliveryOptionSelection: {},
    }),
});
