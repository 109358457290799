import type { MedusaError } from '@medusajs/utils/dist/common/errors';

export interface MedusaErrorResponse {
    response: {
        data: MedusaError;
        status: number;
    };
}

export function isMedusaErrorResponse(value: unknown): value is MedusaErrorResponse {
    return (
        typeof value === 'object' &&
        value != null &&
        'response' in value &&
        typeof value.response === 'object' &&
        value.response != null &&
        'status' in value.response &&
        typeof value.response.status === 'number' &&
        'data' in value.response &&
        typeof value.response.data === 'object'
    );
}
