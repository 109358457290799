import { type NavigationGuard } from 'vue-router';
import { useProvisionTypeAction } from '@/composables/provision-type';
import { ProvisionType } from '@containex/portal-backend-dto';
import { useCompanyAction, useCompanyQuery } from '@/composables/company';
import { useLeadAction } from '@/account/composables/leads';
import { useAuthenticationQuery } from '@/composables/authentication';

// eslint-disable-next-line func-style
export const inferProvisionTypeAndFetchNewLeadsCount: NavigationGuard = async (to) => {
    // infer provision type from URL
    const provisionTypeAction = useProvisionTypeAction();

    const urlTargetName = to.name?.toString().toLowerCase();
    const targetNameIncludesSales = urlTargetName?.includes('/sales/') ?? false;
    const targetNameIncludesRental = urlTargetName?.includes('/rental/') ?? false;

    if (targetNameIncludesSales) {
        provisionTypeAction.setCurrentProvisionType(ProvisionType.Sales);
    } else if (targetNameIncludesRental) {
        provisionTypeAction.setCurrentProvisionType(ProvisionType.Rental);
    } else {
        provisionTypeAction.setCurrentProvisionType(undefined);
    }

    // fetch new leads if the company is a retailer
    // we DO NOT fetch the company (if missing) here on purpose!
    const { isRetailer } = useCompanyQuery();
    const companyAction = useCompanyAction();
    const { isUserLoggedIn } = useAuthenticationQuery();
    if (isUserLoggedIn.value) {
        await companyAction.findCustomerCompanyIfMissing();
    }
    if (isRetailer.value) {
        const leadAction = useLeadAction();
        await leadAction.fetchAmountNewLeads();
    }
};
