import { computed, type ComputedRef } from 'vue';
import { CheckoutDeliveryOption } from '@/checkout/model/checkout-delivery-option';
import { useCheckoutDeliveryOptionStore } from '@/checkout/stores/checkout-delivery-option';
import { type LineItemGroup } from '@containex/portal-backend-api-client';
import { DeliveryTime } from '@containex/portal-backend-dto';

export interface CheckoutDeliveryOptionQuery {
    forLineItemGroupId(this: void, id: string): ComputedRef<CheckoutDeliveryOption>;
}

export interface CheckoutDeliveryOptionAction {
    updateForLineItemGroup(id: string, option: CheckoutDeliveryOption): void;

    updateForLineItemGroups(lineItemGroups: LineItemGroup[]): void;
}

export function useCheckoutDeliveryOptionQuery(): CheckoutDeliveryOptionQuery {
    const store = useCheckoutDeliveryOptionStore();

    return {
        forLineItemGroupId(id: string): ComputedRef<CheckoutDeliveryOption> {
            return computed(
                () => store.lineItemGroupDeliveryOptionSelection[id] ?? CheckoutDeliveryOption.AsSoonAsPossible
            );
        },
    };
}

export function useCheckoutDeliveryOptionAction(): CheckoutDeliveryOptionAction {
    const store = useCheckoutDeliveryOptionStore();

    return {
        updateForLineItemGroup(id: string, option: CheckoutDeliveryOption) {
            store.lineItemGroupDeliveryOptionSelection[id] = option;
        },
        updateForLineItemGroups(lineItemGroups: LineItemGroup[]) {
            lineItemGroups.forEach((group) => {
                switch (group.delivery_time) {
                    case DeliveryTime.Asap:
                        store.lineItemGroupDeliveryOptionSelection[group.id] = CheckoutDeliveryOption.AsSoonAsPossible;
                        break;
                    case DeliveryTime.Custom:
                        store.lineItemGroupDeliveryOptionSelection[group.id] = CheckoutDeliveryOption.Custom;
                        break;
                }
            });
        },
    };
}
