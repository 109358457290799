import { type NavigationGuard } from 'vue-router';
import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication';
import { Navigation } from '@/router/navigation';
import { stringValueOfParam } from '@/util/param';

// eslint-disable-next-line func-style
export const requireNotLoggedIn: NavigationGuard = async (to) => {
    const authenticationAction = useAuthenticationAction();
    const { isUserLoggedIn } = useAuthenticationQuery();

    await authenticationAction.fetchCurrentCustomerIfMissing.perform();

    return !isUserLoggedIn.value
        ? true
        : {
              name: Navigation.SalesProductList,
              params: {
                  market: stringValueOfParam(to.params.market).toLowerCase(),
                  language: stringValueOfParam(to.params.language).toLowerCase(),
              },
          };
};
