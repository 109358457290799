import { type NavigationGuard } from 'vue-router';
import { useMarketAction, useMarketQuery } from '@/composables/market';
import { replacePathParams, stringValueOfParam } from '@/util/param';
import { useLanguage } from '@/composables/useLanguage';
import type { MarketDto } from '@containex/portal-backend-dto';

// eslint-disable-next-line func-style
export const inferParamsIfMissing: NavigationGuard = async (to) => {
    const marketAction = useMarketAction();
    const language = useLanguage();

    const { availableMarkets } = useMarketQuery();

    const code = (await marketAction.initMarketData()).toLowerCase();
    const locale = language.getInitialLanguageLocale().toLowerCase();

    if (code === '' || locale === '') {
        return false;
    }

    const uniqueCodes = uniqueMarketCodesFrom(availableMarkets.value);
    const uniqueLanguages = new Set(language.availableLanguages);

    const urlMarketCode = stringValueOfParam(to.params.market).toLowerCase();
    const urlLanguageCode = stringValueOfParam(to.params.language).toLowerCase();

    const actualMarketCode = uniqueCodes.has(urlMarketCode) ? urlMarketCode : code;
    const actualLanguageCode = uniqueLanguages.has(urlLanguageCode) ? urlLanguageCode : locale;

    if (actualMarketCode === urlMarketCode && actualLanguageCode === urlLanguageCode) {
        return true;
    }

    return replacePathParams(to.path, {
        market: actualMarketCode,
        language: actualLanguageCode,
    });
};

function uniqueMarketCodesFrom(markets: MarketDto[]): Set<string> {
    const codes = markets.map((item) => item.code.toLowerCase());

    return new Set(codes);
}
