import type { HttpStatusCode } from 'axios';

interface ErrorWithStatusCode {
    request: {
        status: HttpStatusCode;
    };
}

export function errorContainsStatusCode(error: unknown): error is ErrorWithStatusCode {
    if (!isObject(error)) {
        return false;
    }

    if (!('request' in error) || !isObject(error.request)) {
        return false;
    }

    return 'status' in error.request && typeof error.request.status === 'number';
}

function isObject(value: unknown): value is Record<string, unknown> {
    return typeof value === 'object' && value != null;
}
