import type { ProvisionType } from '@containex/portal-backend-dto';
import { defineStore } from 'pinia';

interface ProvisionTypeState {
    isChangingProvisionType: boolean;
    currentProvisionType: ProvisionType | undefined;
}

export const useProvisionTypeStore = defineStore('provisionType', {
    state: (): ProvisionTypeState => ({
        isChangingProvisionType: false,
        currentProvisionType: undefined,
    }),
});
