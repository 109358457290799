import { type NavigationGuard } from 'vue-router';
import { stringValueOfParam } from '@/util/param';
import { useLanguage } from '@/composables/useLanguage';

// eslint-disable-next-line func-style
export const initLanguage: NavigationGuard = (to) => {
    const language = useLanguage();

    const languageCode = stringValueOfParam(to.params.language);

    language.setLanguage(languageCode);

    return true;
};
