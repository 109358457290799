import { type NavigationGuard } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { stringValueOfParam } from '@/util/param';
import { useCompanyAction, useCompanyQuery } from '@/composables/company';

// eslint-disable-next-line func-style
export const requireRetailer: NavigationGuard = async (to) => {
    const { isRetailer } = useCompanyQuery();
    const companyAction = useCompanyAction();

    await companyAction.findCustomerCompanyIfMissing();

    return isRetailer.value
        ? true
        : {
              name: Navigation.NotFound,
              params: {
                  market: stringValueOfParam(to.params.market).toLowerCase(),
                  language: stringValueOfParam(to.params.language).toLowerCase(),
              },
          };
};
