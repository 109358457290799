import { useBreakpoints } from '@vueuse/core';

export const breakpoints = useBreakpoints({
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
});

export const isMobile = breakpoints.smaller('lg');
