import { type NavigationGuard } from 'vue-router';
import { useNavigationItemsAction } from '@/composables/navigation-items';
import { NavArea } from '@/types/NavArea';
import { ProvisionType } from '@containex/portal-backend-dto';
import { useProvisionTypeQuery } from '@/composables/provision-type';

// eslint-disable-next-line func-style
export const initNavAreaMyArea: NavigationGuard = (to) => {
    const navigationItemsAction = useNavigationItemsAction();
    navigationItemsAction.setActiveArea(NavArea.MY_AREA);

    return true;
};

// eslint-disable-next-line func-style
export const initNavAreaByProvisionType: NavigationGuard = (to) => {
    const navigationItemsAction = useNavigationItemsAction();
    const { currentProvisionType } = useProvisionTypeQuery();

    const navArea = currentProvisionType.value === ProvisionType.Sales ? NavArea.BUY : NavArea.RENT;
    navigationItemsAction.setActiveArea(navArea);

    return true;
};
