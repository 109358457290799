import { useProvisionTypeStore } from '@/stores/provision-type';
import { ProvisionType } from '@containex/portal-backend-dto';
import { computed, type ComputedRef } from 'vue';

export interface ProvisionTypeQuery {
    isChangingProvisionType: ComputedRef<boolean>;
    currentProvisionType: ComputedRef<ProvisionType | undefined>;
    isRentalProvisionType: ComputedRef<boolean>;
}

export interface ProvisionTypeAction {
    setIsChangingProvisionType(isChangingProvisionType: boolean): void;
    setCurrentProvisionType(currentProvisionType: ProvisionType | undefined): void;
}

export function useProvisionTypeQuery(): ProvisionTypeQuery {
    const store = useProvisionTypeStore();

    return {
        isChangingProvisionType: computed(() => store.isChangingProvisionType),
        currentProvisionType: computed(() => store.currentProvisionType),
        isRentalProvisionType: computed(() => store.currentProvisionType === ProvisionType.Rental),
    };
}

export function useProvisionTypeAction(): ProvisionTypeAction {
    const store = useProvisionTypeStore();

    return {
        setIsChangingProvisionType(isChangingProvisionType: boolean): void {
            store.isChangingProvisionType = isChangingProvisionType;
        },
        setCurrentProvisionType(currentProvisionType: ProvisionType | undefined): void {
            store.currentProvisionType = currentProvisionType;
        },
    };
}
