import { useProvisionTypeQuery } from '@/composables/provision-type';
import { Navigation } from '@/router/navigation';
import { ProvisionType } from '@containex/portal-backend-dto';
import { computed, type ComputedRef } from 'vue';

export function getComputedCartUrlByProvisionType(): ComputedRef<Navigation | undefined> {
    const { currentProvisionType } = useProvisionTypeQuery();

    return computed(() => {
        switch (currentProvisionType.value) {
            case ProvisionType.Sales:
                return Navigation.SalesCart;
            case ProvisionType.Rental:
                return Navigation.RentalCart;
            case undefined:
            default:
                return undefined;
        }
    });
}

export function getComputedProductDetailUrlByProvisionType(): ComputedRef<Navigation | undefined> {
    const { currentProvisionType } = useProvisionTypeQuery();

    return computed(() => {
        switch (currentProvisionType.value) {
            case ProvisionType.Sales:
                return Navigation.SalesProductDetail;
            case ProvisionType.Rental:
                return Navigation.RentalProductDetail;
            case undefined:
            default:
                return undefined;
        }
    });
}
