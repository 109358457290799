import { computed, type ComputedRef } from 'vue';
import { LeadDto, LeadStateFilter, PaginationStep } from '@containex/portal-backend-dto';
import { useLeadStore } from '../stores/leads';
import { leadsApi } from '@containex/portal-backend-api-client';
import { httpClient } from '@/common/api/http-client';
import { isStringEmpty } from '@containex/common-utils';

export interface LeadQuery {
    leads: ComputedRef<LeadDto[]>;
    amount: ComputedRef<number>;
    amountNewLeads: ComputedRef<number>;
}

export interface LeadAction {
    fetchLeads(
        amount: number,
        step: PaginationStep,
        filterByState: LeadStateFilter | undefined,
        filterByOwnLeads: boolean | undefined
    ): Promise<void>;

    fetchLead(leadId: string): Promise<LeadDto>;

    fetchAmountNewLeads(): Promise<void>;

    fetchLeadDocumentAsBase64String(leadId: string, document: string): Promise<string>;
}

export function useLeadQuery(): LeadQuery {
    const store = useLeadStore();

    return {
        leads: computed(() => store.leads),
        amount: computed(() => store.amount),
        amountNewLeads: computed(() => store.amountNewLeads),
    };
}

export function useLeadAction(): LeadAction {
    const store = useLeadStore();

    return {
        async fetchLead(leadId: string): Promise<LeadDto> {
            const response = await leadsApi.fetchLead(httpClient, leadId);
            return response.data;
        },
        async fetchLeads(
            amount: number,
            step: PaginationStep,
            filterByState: LeadStateFilter | undefined,
            filterByOwnLeads: boolean | undefined
        ): Promise<void> {
            const leads = store.leads;
            let pageCursor;

            if (step === PaginationStep.New) {
                pageCursor = null;
            } else {
                const filteredLead = step === PaginationStep.Next ? leads[leads.length - 1] : leads[0];
                pageCursor = {
                    id: filteredLead?.id,
                };
            }

            const response = await leadsApi.fetchLeads(httpClient, {
                amount: String(amount),
                step,
                pageCursorId: pageCursor?.id,
                filterByInternalState: filterByState,
                filterOnlyMyLeads: filterByOwnLeads === true ? 'true' : 'false',
            });

            store.leads = response.data.leads;
            store.amount = response.data.count;
        },
        async fetchAmountNewLeads(): Promise<void> {
            const response = await leadsApi.countLeads(httpClient);
            store.amountNewLeads = response.data.count;
        },
        async fetchLeadDocumentAsBase64String(leadId: string, document: string): Promise<string> {
            const response = await leadsApi.fetchLeadDocument(httpClient, leadId, document);
            if (isStringEmpty(response.data.document)) {
                throw new Error('Document is empty!');
            }

            return response.data.document;
        },
    };
}
