import { globalLogger } from '@cloudflight/logger';

// this catches all errors which are not accounted for and logs them
// in case you want to send them to a backend, take a look at
// https://cloudflightio.github.io/cloudflight-js-utils/api/logger/ for creating the appropriate log consumers
export function setupGlobalErrorHandler(): void {
    window.addEventListener('error', (error) => {
        globalLogger.error('global', error);
    });
}
