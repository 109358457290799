import type { MedusaError } from '@medusajs/utils/dist/common/errors';
import type { Cart } from '@medusajs/medusa/dist/models/cart';

export function instanceOfMedusaError(object: unknown): object is MedusaError {
    return typeof object === 'object' && object != null && 'message' in object && 'type' in object;
}

export function instanceOfMedusaCart(object: unknown): object is Cart {
    return (
        typeof object === 'object' && object != null && 'idempotency_key' in object && 'billing_address_id' in object
    );
}
