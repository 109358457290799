import { computed, type ComputedRef } from 'vue';
import { useCompanyStore } from '@/stores/company';
import {
    type CompanyDto,
    type CompanyUpdateRequestDto,
    type CompanyUpdateResponseDto,
    CustomerType,
} from '@containex/portal-backend-dto';
import { companyApi } from '@containex/portal-backend-api-client';
import { type AxiosResponse, HttpStatusCode } from 'axios';
import { PREPAID } from '@containex/portal-business-logic';
import { httpClient } from '@/common/api/http-client';

export interface CompanyQuery {
    company: ComputedRef<CompanyDto | null>;
    companyName: ComputedRef<string | null>;
    companyUid: ComputedRef<string | null>;
    isRetailer: ComputedRef<boolean>;
    shouldShowOnAccountOption: ComputedRef<boolean>;
}

export interface CompanyAction {
    findCustomerCompany(): Promise<CompanyDto | null>;
    findCustomerCompanyIfMissing(): Promise<void>;
    updateCompany(companyUpdateDto: CompanyUpdateRequestDto): Promise<AxiosResponse<CompanyUpdateResponseDto>>;
}

export function useCompanyQuery(): CompanyQuery {
    const store = useCompanyStore();

    const company = computed(() => store.company);

    return {
        company,
        companyName: computed(() => company.value?.companyName ?? null),
        companyUid: computed(() => {
            const uids = company.value?.uids;
            const countryCode = company.value?.billingAddress.countryCode?.toLowerCase();

            if (uids == null || countryCode == null) {
                return null;
            }
            return uids[countryCode] ?? null;
        }),
        shouldShowOnAccountOption: computed(() => {
            const paymentInformation = company.value?.paymentInformation;

            return paymentInformation != null && paymentInformation !== '' && paymentInformation !== PREPAID;
        }),
        isRetailer: computed(() => store.company?.customerType === CustomerType.Retailer),
    };
}

export function useCompanyAction(): CompanyAction {
    const companyStore = useCompanyStore();

    return {
        async findCustomerCompany(): Promise<CompanyDto | null> {
            const { data } = await companyApi.getCompany(httpClient);

            companyStore.company = data;

            return companyStore.company;
        },
        async findCustomerCompanyIfMissing(): Promise<void> {
            if (companyStore.company != null) {
                return;
            }

            try {
                await this.findCustomerCompany();
            } catch {
                // do nothing
            }
        },
        async updateCompany(
            companyUpdateDto: CompanyUpdateRequestDto
        ): Promise<AxiosResponse<CompanyUpdateResponseDto>> {
            const response = await companyApi.updateCompany(httpClient, companyUpdateDto);

            if (response.status === HttpStatusCode.Ok && response.data != null) {
                companyStore.company = response.data;
            }

            return response;
        },
    };
}
